<template>
  <v-main>
    <v-container>
      <v-row>
        <v-col>
          <h1>設定</h1>
        </v-col>
      </v-row>
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="doLoad(false)">
            <v-progress-circular
              v-show="loading"
              :indeterminate="loading"
            ></v-progress-circular>
            <v-icon v-show="!loading">mdi-reload</v-icon>
          </v-btn>
        </v-card-title>
        <v-data-table
          :headers="settingHeaders"
          :items="settingItems"
          :loading="loading"
          :itemsPerPage="-1"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-icon small class="mr-2" @click="doEdit(item)">
              mdi-pencil
            </v-icon>
          </template>
          <template v-slot:no-data>
            現在設定された情報はありません。
          </template>
        </v-data-table>
      </v-card>
      <setting-editor
        :dialog="dialog"
        :setting="editSetting"
        @close="close"
      ></setting-editor>
    </v-container>
  </v-main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { getSettings } from '@/api'
import SettingEditor from '@/components/setting-editor'

export default {
  name: 'SettingIndex',
  components: {
    SettingEditor
  },
  data() {
    return {
      loading: false,
      dialog: false,
      settingHeaders: [
        { text: '設定名', value: 'name' },
        { text: '説明', value: 'description', sortable: false },
        { text: '編集', value: 'action', sortable: false }
      ],
      editSetting: {}
    }
  },
  computed: {
    ...mapGetters(['settingItems'])
  },
  created() {
    this.initSetting(true)
  },
  methods: {
    ...mapActions(['setSettings', 'setFullscreenLoading']),
    async doLoad(storeCheck) {
      if (this.loading) {
        return
      }
      this.loading = true
      await this.initSetting(storeCheck)
      this.loading = false
    },
    async initSetting(storeCheck) {
      if (storeCheck && Object.keys(this.$store.getters.settings).length > 0) {
        return
      }
      let res
      try {
        res = await getSettings()
      } catch (e) {
        console.error(e)
        alert('サーバエラーが発生しました')
        return
      }
      this.setSettings(res.settings)
    },
    doEdit(setting) {
      this.editSetting = setting
      this.dialog = true
    },
    close() {
      this.dialog = false
    }
  }
}
</script>
