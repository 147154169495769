<template>
  <v-dialog
    v-model="dialog"
    max-width="920"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-card-title>
        <span class="headline">編集</span>
      </v-card-title>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="item.name"
                  label="設定名"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="item.description"
                  label="説明"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div
                  v-if="setting.name === 'device_alive_alert_message_template'"
                >
                  <p class="caption text--secondary">
                    ※以下プレイスホルダ（ {timestamp}
                    など）を含めると送信時に置換されます。
                  </p>
                  <p class="body-2">
                    {timestamp}
                    と入力したところにアラート日時が表示されます。<br />
                    {group} と入力したところにグループ名が表示されます。<br />
                    {sensor} と入力したところにセンサ名が表示されます。
                  </p>
                </div>
                <v-textarea
                  v-if="isTextarea"
                  v-model="item.value"
                  label="値"
                  outlined
                ></v-textarea>
                <v-text-field
                  v-if="isText"
                  v-model="item.value"
                  label="値"
                  outlined
                ></v-text-field>
                <v-text-field
                  v-if="isNumber"
                  v-model="item.value"
                  label="値"
                  :suffix="item.suffix"
                  :rules="rules.number"
                ></v-text-field>
                <div v-if="isRadio">
                  <p class="text--secondary caption">値</p>
                  <v-radio-group v-model="item.value" column>
                    <v-radio
                      v-for="i in item.values"
                      :key="i.value"
                      :label="i.label"
                      :value="i.value"
                    ></v-radio>
                  </v-radio-group>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed @click="close">
            キャンセル
          </v-btn>
          <v-btn depressed dark :color="mainColor" @click="save">保存</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { putSetting } from '@/api'
import { MAIN_COLOR } from '@/constants'

export default {
  name: 'SettingEditor',
  props: {
    dialog: Boolean,
    setting: Object
  },
  data() {
    return {
      mainColor: MAIN_COLOR,
      valid: true,
      rules: {
        number: [v => v === '' || !isNaN(v) || '数値を入力してください']
      }
    }
  },
  computed: {
    ...mapGetters(['settings']),
    item() {
      let data = Object.assign({}, this.setting)
      if (this.isConvertTimeValue) {
        data.value /= 60
      }
      return data
    },
    isConvertTimeValue() {
      return (
        this.setting.name === 'device_alerting_interval_second' ||
        this.setting.name === 'user_device_notification_interval_second' ||
        this.setting.name === 'notification_suppression_by_second' ||
        this.setting.name === 'notification_suppression_disable_alert_second' ||
        this.setting.name === 'number_of_alerts_to_notify_second' ||
        this.setting.name ===
          'user_device_notification_interval_second_for_email' ||
        this.setting.name === 'notification_suppression_by_second_for_email' ||
        this.setting.name ===
          'notification_suppression_disable_alert_second_for_email'
      )
    },
    isText() {
      return this.setting.formType === 'text'
    },
    isTextarea() {
      return this.setting.formType === 'textarea'
    },
    isNumber() {
      return this.setting.formType === 'number'
    },
    isRadio() {
      return this.setting.formType === 'radio'
    }
  },
  methods: {
    ...mapActions(['setSettings', 'setFullscreenLoading']),
    close() {
      this.$refs.form.resetValidation()
      this.$emit('close')
    },
    async save() {
      if (!this.$refs.form.validate()) {
        return
      }
      this.setFullscreenLoading(true)
      try {
        let data = Object.assign({}, this.settings)
        data[this.item.name] = this.item.value
        if (this.isConvertTimeValue) {
          data[this.item.name] *= 60
        }
        const res = await putSetting(data)
        this.setSettings(res.settings)
      } catch (e) {
        console.error(e)
        alert('サーバエラーが発生しました')
      }
      this.close()
      this.setFullscreenLoading(false)
    }
  }
}
</script>
